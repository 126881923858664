import React from 'react'
import logo from '../Images/Trans Logo@3x.png'
import Address from '../Images/AddressIcon.svg'
import Email from '../Images/EmailIcon.svg'
import contact from '../Images/Phone Icon.svg'
import { NavLink } from 'react-router-dom'
// import FooterImg from '../Images/Footer Image_1.svg'

const Footer = () => {
  return (
    
    <div className='bg'>
    <footer id="sticky-footer" className="flex-shrink-0 py-2 text-white-50 ">
    {/* <img src={FooterImg} alt='footer' className='footerImage'/> */}
    <div className='container footer mt-5 mb-3'>
      <div className='row mb-5'>
        <div className='col-md-8 col-xs-12 col-sm-12'>
        <NavLink
              as="button"
              className="nav-link m-2 p-0 Mobile logo"
              aria-current="page"
              to="/"
            >
              <img
                src={logo}
                alt="Logo..."
                width="400"
                height="120"
                className="d-inline-block logoholder mb-5"
              />
            </NavLink>
        </div>
        <div className='col-md-4 col-xs-12 col-sm-12 d-flex flex-column text-light'>
          <div className='address mb-3 d-flex flex-row'>
            <img src={Address} alt='address' className='me-2'/>
            <div className='d-flex flex-column'>
            <span> No.49 S.Y.No# 101</span>
            <span> 3rd Cross, NR Layout, </span>
            <span> FCI Godown Road</span>
            <span> Vijinapura, Bangalore-16</span>
            </div>

          </div>
          <div className='Email mb-3 d-flex flex-row'>
          <img src={Email} alt='email' className='me-2'/>
            <div className='d-flex flex-column'>
            <span>info@transinfosystems.com</span>
            </div>
          </div>
          <div className='Contact mb-3 d-flex flex-row'>
          <img src={contact} alt='contact' className='me-2'/>
            <div className='d-flex flex-column'>
            <span>+919845117110</span>
            </div>
          </div>
        </div>
      </div>
      <div className='row footerList'>
        <ol>
          <li>
          <NavLink  className='linkStyles' to='/privacypolicy'>Privacy Policy</NavLink>
            {/* <a href='https://merchant.razorpay.com/policy/LS8IVNSOf9sU74/privacy' rel="noopener noreferrer" target='_blank' >Privacy Policy</a> */}
          </li>
          <li>
          <NavLink  className='linkStyles' to='/termsandconditions'>Terms of use</NavLink>
            {/* <a href='https://merchant.razorpay.com/policy/LS8IVNSOf9sU74/terms' rel="noopener noreferrer" target='_blank' > Terms of use</a> */}
          </li>
          <li>
            {/* <a href='https://merchant.razorpay.com/policy/LS8IVNSOf9sU74/refund' rel="noopener noreferrer" target='_blank' >Refund Policy</a> */}
          <NavLink  className='linkStyles' to='/cancellationsandrefund'>Refund Policy</NavLink>
          </li>
          <li>
            {/* <a href='https://merchant.razorpay.com/policy/LS8IVNSOf9sU74/shipping' rel="noopener noreferrer" target='_blank' >Shipping policy</a> */}
          <NavLink  className='linkStyles' to='/shippinganddelivery'>Shipping policy</NavLink>
          </li>
        </ol>
      </div>
    </div>
    <div className="container text-center">
      <small>COPYRIGHT &copy;2022 TRANS INFOSYSTEMS</small>
    </div>
  </footer>
  </div>
  )
}

export default Footer