import React from 'react'
import PRoute from './Routes/PRoute'

const App = () => {
  return (
    <>
      <PRoute/>
    </>
  )
}

export default App