import React, { useState } from 'react'
import Header from '../components/Header'
import Colorbox from '../Images/colorbox.png'
import Address from '../Images/AddressIcon.svg'
import Email from '../Images/EmailIcon.svg'
import contact from '../Images/Phone Icon.svg'
import Footer from '../components/Footer'
import emailjs from '@emailjs/browser';
import ImageOverlayWhite1 from '../Images/Gray Square.svg'
import ImageInfinity from '../Images/Infinity.svg'
import ImageInfinityWhite from '../Images/Gray Infinity.svg'

const ContactUs = () => {
    const [ContactData, setContactData] = useState({
        name:'',
        email:'',
        phone:'',
        message:''
    })

    const handleChange = (e) => {
        setContactData({...ContactData,[e.target.name]:e.target.value})
    }

    const onSubmit = (e)=>{
        e.preventDefault();
        emailjs.sendForm(
          'service_v43dxgj',
          'template_1c6wa9l',
          e.target,
          'user_QjCG2AGooixHigdQydkaC'
        )
          .then((response) => {
            console.log('SUCCESS!', response.status, response.text);
          })
          .catch((err) => {
            console.log('FAILED...', err);
          });
    }

  return (
    <>
    <Header/>
    <div className="contact airforceBlue ">
    <div className="text-white text-center Slider-TextC">
                <h3 className="mb-3 textGoldColor RacingFont">Contact Us</h3>
                <p className='fs-5'>Our team is always ready to help you. we’re just a ring away !</p>
              </div>
              {/* #### square Images ###  */}
               <div className="imgoverlays1"></div>
               <div className="imgoverlays2"></div>
               <div className="imgoverlays3"></div>
               <div className="imgoverlays4"></div>
         
    </div>
    <div className='container-fluid airforceBlue pt-5 pb-2 position-relative bg-diff'>

    <div className='imgInfintity'>
        <img src={ImageInfinity} alt='ImageOverlay7'/>
       </div>
    <div className='imgInfintityWhite'>
        <img src={ImageInfinityWhite} alt='ImageOverlay7'/>
       </div>
    <div className='imgoverlaysWhite1'>
        <img src={ImageOverlayWhite1} alt='ImageOverlay7'/>
       </div>
   <div className='imgoverlaysWhite2'>
        <img src={ImageOverlayWhite1} alt='ImageOverlay7'/>
       </div>
   <div className='imgoverlaysWhite3'>
        <img src={ImageOverlayWhite1} alt='ImageOverlay7'/>
       </div>
   <div className='imgoverlaysWhite4'>
        <img src={ImageOverlayWhite1} alt='ImageOverlay7'/>
       </div>
       <div className='imgoverlays7'></div>
       <div className='imgoverlaysGroup'>
       {/* <img src={Colorbox} alt=''/> */}
       </div>
       <div className='row'>
         <div className='col-12 p-5 my-2'>
         <div className='d-flex justify-content-center'>
         <div className='borderOnLeft ps-2'>
           <h2 className='hearderServices RacingFont'>
           Contact us
           </h2>
           <div className='ServicesList'>
           <ul>
             <li><a href="#home">INTEGRATE <span className='text-light ms-3'>|</span></a></li>
             <li><a href="#news">CONSOLIDATE <span className='text-light ms-3'>|</span></a></li>
             <li><a href="#contact">TRANSCEND <span className='text-light ms-3'></span></a></li>
           </ul>
           </div>
         </div>
         </div>
         </div>
       </div>
     
     
   <div className="container mt-5 mb-5 contact-con me-4">
   
        <div className="card row d-flex justify-content-center mx-auto mb-5">
        <div className='row'>
            <div className='col-md-12 col-xs-12 col-sm-12 col-lg-6 p-5 border_Right'>
          
          
          <div className='Contact my-3 d-flex flex-row ps-5'>
          <img src={contact} alt='contact' className='me-2'/>
            <div className='d-flex flex-column text-white'>
            <span>+919845117110</span>
            </div>
          </div>
          <div className='Email mb-3 d-flex flex-row ps-5'>
          <img src={Email} alt='email' className='me-2'/>
            <div className='d-flex flex-column text-white'>
            <span>info@transinfosystems.com</span>
            </div>
          </div>
          <div className='address mb-3 d-flex flex-row ps-5'>
            <img src={Address} alt='address' className='me-2'/>
            <div className='d-flex flex-column text-white'>
            <span> No.49 S.Y.No# 101</span>
            <span> 3rd Cross, NR Layout, </span>
            <span> FCI Godown Road</span>
            <span> Vijinapura, Bangalore-16</span>
            </div>

          </div>
        
            </div>
            <div className='col-md-12 col-xs-12 col-sm-12 col-lg-6'>
                <h1 className='text-light m-3'>Location</h1>
             
                <iframe
                    width="100%"
                    height="300"
                    style={{ border: "0" }}
                    allowFullScreen
                    loading="lazy"
                    title='gmp'
                    src={`https://www.google.com/maps/embed/v1/place?q=13.0005,77.6678&key=AIzaSyAH5P0VXp3xIekM6K3FedhnOQ2qY1de8VQ`}
                   
                ></iframe>
            </div>
        </div>
        </div>
    </div>
<h2 className='text-center text-light'>Let’s Connect to discuss your project </h2>
    </div>
    <div className='container mt-3 position-relative bg-difft'>
    <div className='imgoverlaysWhite5'>
        <img src={ImageOverlayWhite1} alt='ImageOverlay7'/>
       </div>
       <div className='imgoverlaysWhite6'>
        <img src={ImageOverlayWhite1} alt='ImageOverlay7'/>
       </div>
       <div className='imgoverlaysWhite7'>
        <img src={ImageOverlayWhite1} alt='ImageOverlay7'/>
       </div>
       <div className='imgoverlaysWhite8'>
        <img src={ImageOverlayWhite1} alt='ImageOverlay7'/>
       </div>
       <div className='imgoverlaysWhite9'>
        <img src={ImageOverlayWhite1} alt='ImageOverlay7'/>
       </div>
    <form onSubmit={onSubmit}>
        <div className='row' style={{maxWidth: '80%', margin: 'auto'}}>
            <div className='col-md-4 col-xs-12 col-sm-12 col-lg-4 mb-3'>
                <input onChange={handleChange} type='text' placeholder='Name' name='name' value={ContactData.name}/>
            </div>
            <div className='col-md-4 col-xs-12 col-sm-12 col-lg-4 mb-3'>
                <input onChange={handleChange} type='text' placeholder='Email' name='email' value={ContactData.email}/>
            </div>
            <div className='col-md-4 col-xs-12 col-sm-12 col-lg-4 mb-3'>
                <input onChange={handleChange} type='text' placeholder='Phone' name='phone' value={ContactData.phone}/>
            </div>
            <div className='col-12'>
            <div className="textwrapper"><textarea onChange={handleChange} cols="2" rows="7" placeholder='Message' id="rules" name='message' value={ContactData.message}/></div>
            </div>
            <button type="submit" className=' col-3 buttonColor m-3 border-0'>Send Message</button>
    
        </div>
        </form>
    </div>
    <Footer/>
    {/* <Slider/> */}
    </>
  )
}

export default ContactUs