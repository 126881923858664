import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import Hiz from '../Images/Hiz Logo@3x.png'
import Ras from '../Images/RasInfo.png'
import brains from '../Images/Brains.png'
import thesmartroot from '../Images/THESMARTROOT.png'
import joy from '../Images/joy.png'

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import "./styles.css";

// import required modules
import {Autoplay, Pagination, Navigation, Mousewheel, Keyboard } from "swiper";

const Swipers = () => {
  return (
    <>
    <div className=''>
    <section className=''>
      <Swiper
        // slidesPerView={3}
        // spaceBetween={10}
        slidesPerGroup={1}
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
            
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 50,
          },
        }}
        loop={true}
        loopFillGroupWithBlank={true}
        // mousewheel={true}
        keyboard={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation, Mousewheel, Keyboard]}
       
        className="mySwiper pb-5"
      >
        <SwiperSlide>
          <img className='' src={Hiz} alt="Hiz"></img>
        </SwiperSlide>
        <SwiperSlide>
        <img className='' src={Ras} alt="Hiz"></img>
        </SwiperSlide>
        <SwiperSlide>
        <img className='' src={brains} alt="Hiz"></img>
        </SwiperSlide>
        <SwiperSlide>
        <img className='' src={thesmartroot} alt="Hiz"></img>
        </SwiperSlide>
        <SwiperSlide>
        <img className='' src={joy} alt="Hiz"></img>
        </SwiperSlide>
      </Swiper>
      {/* <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div> */}
    </section>
    </div>
    </>
  )
}

export default Swipers
