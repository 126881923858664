import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'

const CancellationsAndRefund = () => {
  return (
    <div>
        <Header/>
        <div className="CancellationsAndRefund airforceBlue ">
        <div className="text-white text-center Slider-TextCR">
                <h2 className="mb-3 textGoldColor RacingFont">Cancellation & Refund Policy</h2>
                {/* <p className='fs-5'>OUR APPROACH TO PRIVACY</p> */}
              </div>
        </div>
        <div className='container text-white' style={{marginTop:'5%',marginBottom:'5%'}}>
            <div className='row'> 
               <div className='col-12 mb-4'>
              <p>No cancellations & Refunds are entertained</p>
               </div>
                
            </div>
        </div> 
        <Footer/>
    </div>
  )
}

export default CancellationsAndRefund