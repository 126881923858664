import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import InfinityImage from '../Images/Infinity.svg'
import ImageOverlay5 from '../Images/square.svg'

const Services = () => {
  const [CardHov1, setCardHov1] = useState(false);
  const [CardHov2, setCardHov2] = useState(false);
  const [CardHov3, setCardHov3] = useState(false);
  const [CardHov4, setCardHov4] = useState(false);
  const [CardHov5, setCardHov5] = useState(false);
  const [CardHov6, setCardHov6] = useState(false);

  const MouseEnter1 = ()=> {
    setCardHov1(true)
  }
  const MouseLeave1 = ()=> {
    setCardHov1(false)
  }

  const MouseEnter2 = () => {
    setCardHov2(true)
  }

  const MouseLeave2 = ()=> {
    setCardHov2(false)
  }

  const MouseEnter3 = () => {
    setCardHov3(true)
  }

  const MouseLeave3 = ()=> {
    setCardHov3(false)
  }
  const MouseEnter4 = () => {
    setCardHov4(true)
  }

  const MouseLeave4 = ()=> {
    setCardHov4(false)
  }
  const MouseEnter5 = () => {
    setCardHov5(true)
  }

  const MouseLeave5 = ()=> {
    setCardHov5(false)
  }
  const MouseEnter6 = () => {
    setCardHov6(true)
  }

  const MouseLeave6 = ()=> {
    setCardHov6(false)
  }

  return (
    <>
         <div className='container-fluid airforceBlue pt-2 pb-5 position-relative'>
          <div className='InfinityImage'>
            <img src={InfinityImage} alt='InfinityImage'/>
          </div>
          <div className='imgoverlays5'>
            <img src={ImageOverlay5} alt='imgoverlays5'/>
          </div>
          <div className='row'>
            <div className='col-12 p-5 my-2'>
            <div className='d-flex justify-content-center'>
            <div className='borderOnLeft ps-2'>
              <h2 className='hearderServices RacingFont'>
                Our Services
              </h2>
              <div className='ServicesList'>
              <ul>
                <li><a href="#home">INTEGRATE <span className='text-light ms-3'>|</span></a></li>
                <li><a href="#news">CONSOLIDATE <span className='text-light ms-3'>|</span></a></li>
                <li><a href="#contact">TRANSCEND <span className='text-light ms-3'></span></a></li>
              </ul>
              </div>
            </div>
            </div>
            </div>
          </div>
        
        
      <div className="container mt-5 mb-3">
  <div className="row row-padd">
    <div className="col-md-6 col-sm-12 col-lg-4 col-xs-12 col-xl-4 col-padd"  onMouseEnter={MouseEnter1} onMouseLeave={MouseLeave1}>
    {
      CardHov1?
      <div className="card p-3 mb-2 BackgroundGold">
        <div className="d-flex justify-content-between row borderdallSides align-content-end">
           <div className="marginFullCard divEnd align-text-bottom"> 
            <div className=" WIdth80 textEb">
            INFRASTUCTURE SETUP SERVICES AND OUTSOURCING
            </div>
            <div className=" float-end me-2 fw-bold d-flex flex-row">
            <NavLink to='/' className='ReadMore'>
              READ MORE
            </NavLink>
              <div className="arrow mt-1"></div>
              {/* <div className="arrow"></div> */}
            </div>
           </div> 
        </div>
        
      </div>
      :
      <div className="card p-3 mb-2">
        <div className="d-flex justify-content-between row g-0">
            <div className="col-12 row manageddiv g-lg-0">
              <div className="col-7 col-sm-6 col-md-5 b textb ImageDivSmall">
                INFRASTUCTURE
              </div>
              <div className="col-5 col-sm-6 col-md-5 col-lg-5 col-xl-5 b d-flex flex-column textb">
              <span>SETUP</span>
              <span>SERVICES</span>
              <span>AND</span>
              <span>OUTSOURCING</span>
              </div>
            </div>
            {/* <div className="col-6 managedall">00</div> */}
        </div>
        <div className='smallText m-2'>
          <p>
          It can be a challenge to manage IT infrastructure across 
          vendors and platforms, especially when dealing with external
          support personnel who are not familiar with your unique 
          IT environment.Our managed infrastructure services improve 
          your organization’s agility hile minimizing complexity and
          costs
          </p>
        </div>
      </div>
    }
    </div>

    {/* EmDIv */}
    <div className="col-md-6 col-sm-12 col-lg-4 col-xs-12 col-xl-4 col-padd" onMouseEnter={MouseEnter2} onMouseLeave={MouseLeave2}>
    {
      CardHov2 ?
      <div className="card p-3 mb-2 BackgroundGold">
        <div className="d-flex justify-content-between row borderdallSides align-content-end">
           <div className="marginFullCard divEnd align-text-bottom"> 
            <div className=" WIdth80 textEb">
            IT EQUIPMENT SALES AND RENTALS
            </div>
            <div className=" float-end me-2 fw-bold d-flex flex-row">
            <NavLink to='/' className='ReadMore'>
              READ MORE
            </NavLink>
              <div className="arrow mt-1"></div>
              {/* <div className="arrow"></div> */}
            </div>
           </div> 
        </div>
        
      </div>
      :
      <div className="card p-3 mb-2">
        <div className="d-flex justify-content-between row g-0">
            <div className="col-12 row manageddiv g-lg-0">
              <div className="col-6 col-sm-4 col-xs-4 b textb ImageDivSmall text-center">
              IT EQUIPMENT
              </div>
              <div className="col-5 col-sm-6 col-md-5 col-lg-5 col-xl-5 b d-flex flex-column textb">
              <span>SALES</span>
              <span>AND</span>
              <span>RENTALS</span>
              </div>
            </div>
            {/* <div className="col-6 managedall">00</div> */}
        </div>
        <div className='smallText m-2'>
          <p>
          It can be a challenge to manage IT infrastructure across 
          vendors and platforms, especially when dealing with external
          support personnel who are not familiar with your unique 
          IT environment.Our managed infrastructure services improve 
          your organization’s agility hile minimizing complexity and
          costs
          </p>
        </div>
      </div>
    }
    </div>

    <div className="col-md-6 col-sm-12 col-lg-4 col-xs-12 col-xl-4 col-padd" onMouseEnter={MouseEnter3} onMouseLeave={MouseLeave3}>
    {
      CardHov3?
      <div className="card p-3 mb-2 BackgroundGold">
        <div className="d-flex justify-content-between row borderdallSides align-content-end">
           <div className="marginFullCard divEnd align-text-bottom"> 
            <div className=" WIdth80 textEb">
            MANAGED IT SOLUTIONS
            </div>
            <div className=" float-end me-2 fw-bold d-flex flex-row">
            <NavLink to='/' className='ReadMore'>
              READ MORE
            </NavLink>
              <div className="arrow mt-1"></div>
              {/* <div className="arrow"></div> */}
            </div>
           </div> 
        </div>
        
      </div>
      :
      <div className="card p-3 mb-2">
        <div className="d-flex justify-content-between row g-0">
            <div className="col-12 row manageddiv g-lg-0">
              <div className="col-6 b textb ImageDivSmall text-center ps-2">
                MANAGED
              </div>
              <div className="col-5 col-sm-6 col-md-5 col-lg-5 col-xl-5 b d-flex flex-column textb">
              <span>IT SOLUTIONS</span>
              </div>
            </div>
            {/* <div className="col-6 managedall">00</div> */}
        </div>
        <div className='smallText m-2'>
          <p>
          It can be a challenge to manage IT infrastructure across 
          vendors and platforms, especially when dealing with external
          support personnel who are not familiar with your unique 
          IT environment.Our managed infrastructure services improve 
          your organization’s agility hile minimizing complexity and
          costs
          </p>
        </div>
      </div>
    }
    </div>

    {/* EmDIv */}
    <div className="col-md-6 col-sm-12 col-lg-4 col-xs-12 col-xl-4 col-padd" onMouseEnter={MouseEnter4} onMouseLeave={MouseLeave4}>
    {
      CardHov4?
      <div className="card p-3 mb-2 BackgroundGold">
        <div className="d-flex justify-content-between row borderdallSides align-content-end">
           <div className="marginFullCard divEnd align-text-bottom"> 
            <div className=" WIdth80 textEb">
            HOSTING SERVICES AND SECURITY
            </div>
            <div className=" float-end me-2 fw-bold d-flex flex-row">
            <NavLink to='/' className='ReadMore'>
              READ MORE
            </NavLink>
              <div className="arrow mt-1"></div>
              {/* <div className="arrow"></div> */}
            </div>
           </div> 
        </div>
        
      </div>
      :
      <div className="card p-3 mb-2">
        <div className="d-flex justify-content-between row g-0">
            <div className="col-12 row manageddiv g-lg-0">
              <div className="col-6 b textb ImageDivSmall text-center ps-3">
              HOSTING
              </div>
              <div className="col-5 col-sm-6 col-md-5 col-lg-5 col-xl-5 b d-flex flex-column textb">
              <span>SERVICES</span>
              <span>AND</span>
              <span>SECURITY</span>
              </div>
            </div>
            {/* <div className="col-6 managedall">00</div> */}
        </div>
        <div className='smallText m-2'>
          <p>
          It can be a challenge to manage IT infrastructure across 
          vendors and platforms, especially when dealing with external
          support personnel who are not familiar with your unique 
          IT environment.Our managed infrastructure services improve 
          your organization’s agility hile minimizing complexity and
          costs
          </p>
        </div>
      </div>
    }
    </div>

    <div className="col-md-6 col-sm-12 col-lg-4 col-xs-12 col-xl-4 col-padd" onMouseEnter={MouseEnter5} onMouseLeave={MouseLeave5}>
    {
      CardHov5? 
      <div className="card p-3 mb-2 BackgroundGold">
        <div className="d-flex justify-content-between row borderdallSides align-content-end">
           <div className="marginFullCard divEnd align-text-bottom"> 
            <div className=" WIdth80 textEb">
            CLOUD SOLUTIONS
            </div>
            <div className=" float-end me-2 fw-bold d-flex flex-row">
            <NavLink to='/' className='ReadMore'>
              READ MORE
            </NavLink>
              <div className="arrow mt-1"></div>
              {/* <div className="arrow"></div> */}
            </div>
           </div> 
        </div>
        
      </div>
      :
      <div className="card p-3 mb-2">
        <div className="d-flex justify-content-between row g-0">
            <div className="col-12 row manageddiv">
              <div className="col-6 b textb ImageDivSmall text-center ps-5">
              CLOUD 
              </div>
              <div className="col-5 col-sm-6 col-md-5 col-lg-5 col-xl-5 b d-flex flex-column textb">
              <span>SOLUTIONS</span>
              </div>
            </div>
            {/* <div className="col-6 managedall">00</div> */}
        </div>
        <div className='smallText m-2'>
          <p>
          It can be a challenge to manage IT infrastructure across 
          vendors and platforms, especially when dealing with external
          support personnel who are not familiar with your unique 
          IT environment.Our managed infrastructure services improve 
          your organization’s agility hile minimizing complexity and
          costs
          </p>
        </div>
      </div>
    }
    </div>

    {/* EmDIv */}
    <div className="col-md-6 col-sm-12 col-lg-4 col-xs-12 col-xl-4 col-padd" onMouseEnter={MouseEnter6} onMouseLeave={MouseLeave6}>
    {
      CardHov6? 
      <div className="card p-3 mb-2 BackgroundGold">
        <div className="d-flex justify-content-between row borderdallSides align-content-end">
           <div className="marginFullCard divEnd align-text-bottom"> 
            <div className=" WIdth80 textEb">
            CUSTOM IOT AND SYSTEM INTEGRATION
            </div>
            <div className=" float-end me-2 fw-bold d-flex flex-row">
            <NavLink to='/' className='ReadMore'>
              READ MORE
            </NavLink>
              <div className="arrow mt-1"></div>
              {/* <div className="arrow"></div> */}
            </div>
           </div> 
        </div>
        
      </div>
    :
    <div className="card p-3 mb-2">
            <div className="d-flex justify-content-between row g-0">
                <div className="col-12 row manageddiv">
                  <div className="col-7 b textb ImageDivSmall text-center">
                  CUSTOM IOT
                  </div>
                  <div className="col-5 col-sm-6 col-md-5 col-lg-5 col-xl-5 b d-flex flex-column textb">
                  <span>AND</span>
                  <span>SYSTEM</span>
                  <span>INTEGRATION</span>
                  </div>
                </div>
                {/* <div className="col-6 managedall">00</div> */}
            </div>
            <div className='smallText m-2'>
              <p>
              It can be a challenge to manage IT infrastructure across 
              vendors and platforms, especially when dealing with external
              support personnel who are not familiar with your unique 
              IT environment.Our managed infrastructure services improve 
              your organization’s agility hile minimizing complexity and
              costs
              </p>
            </div>
          </div>
    }
    </div>

  </div>
</div>
        </div>
    </>
  )
}

export default Services