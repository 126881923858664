import React from 'react'
import { NavLink } from 'react-router-dom'
import Header from '../components/Header'
import Slider from '../components/Slider'
import AboutUs from './AboutUs'
import Services from './Services'
import Swipers from './Swipers'

import Colorbox from '../Images/colorbox.png'
import Footer from '../components/Footer'
import imgoverlays7 from '../Images/square.svg'

const Home = () => {

 
  
  return (
    <>
        <Header/>
        <Slider/>
       <Services/>
      <AboutUs/>
      <>
    <div className='container-fluid airforceBlueGrad pt-5 position-relative customers'>
       <div className='imgoverlays7'>
        <img src={imgoverlays7} alt='ImageOverlay7'/>
       </div>
       <div className='imgoverlaysGroup'>
       <img src={Colorbox} alt=''/>
       </div>
       <div className='row'>
         <div className='col-12 p-5 my-2'>
         <div className='d-flex justify-content-center'>
         <div className='borderOnLeft ps-2'>
           <h2 className='hearderServices RacingFont'>
           Our Customers
           </h2>
           <div className='ServicesList'>
           <ul>
             <li><a href="#home">Done <span className='text-light ms-3'>|</span></a></li>
             <li><a href="#news">Delivered <span className='text-light ms-3'>|</span></a></li>
             <li><a href="#contact">Applauded <span className='text-light ms-3'></span></a></li>
           </ul>
           </div>
         </div>
         </div>
         </div>
       </div>
       <div className=''>
       <div className='col-8'>
         <div className='d-flex justify-content-end ms-5'>
         <p className='mobileTextSize w-50 fs-5 text-light'>We come highly commended & recommended for the
            solutions that we have consistently delivered accross 
            different regions</p> 
         </div>
           
         </div>
       </div>
     
     
   <div className="container mt-5 pb-5">
   <h3 className=' ms-5 textGoldColor fw-bolder'>Valuable Customers</h3>

<Swipers/>


  
    </div>
    </div>
   <Footer/>
    </>
    </>
  )
}

export default Home