import React from 'react'
import { NavLink } from 'react-router-dom'
import InfinityImage from '../Images/colorInfinity.png'
import ImageOverlay6 from '../Images/square.svg'

const AboutUs = () => {
  return (
    <>
    <div className='container-fluid airforceBlue pt-5 position-relative'>
       <div className='InfinityImageR'>
        <img src={InfinityImage} alt='Infinity'/>
       </div>
       <div className='imgoverlays6'>
        <img src={ImageOverlay6} alt='ImageOverlay6'/>
       </div>
       <div className='row'>
         <div className='col-12 p-5 my-2'>
         <div className='d-flex justify-content-center'>
         <div className='borderOnLeft ps-2'>
           <h2 className='hearderServices RacingFont'>
           ABOUT TRANS INFOSYSTEMS
           </h2>
           <div className='ServicesList'>
           <ul>
             <li><a href="#home">VALUE <span className='text-light ms-3'>|</span></a></li>
             <li><a href="#news">INTEGRITY <span className='text-light ms-3'>|</span></a></li>
             <li><a href="#contact">TRANSPERANCY <span className='text-light ms-3'></span></a></li>
           </ul>
           </div>
         </div>
         </div>
         </div>
       </div>
     
     
   <div className="container mt-5 mb-3">
     <div className='about-Us'>
     <p>
    <span className='Trans RacingFont'>Trans</span> Infosystems is a strategic provider of IT Hardware, IT assets and IT peripherals on rent, 
along with onsite support and services . We are providing reliable, high quality IT services to 
leading Private, Public & Corporate/ MNC organisations.We are specialist in fulfilling short term 
or long term requirements for our clients.
     </p>
<p>
Trans Infosystems offers wide range of IT assets on Rental such as, Laptops, desktops, servers, 
switches and other valuable IT equipment's with the latest configurations to keep the pace with the 
changing trends every day. We help our customers to invest on their core business rather than 
investinng huge money on buying assets. We help clients to save there capital , gain tax benefits and
beat technological obsolescence.
</p>
     <div className="fw-bold">
         <NavLink to='/' className='ReadMoreG d-flex flex-row'>
           READ MORE
           <div className="arrowG mt-1 ms-1"></div>
         </NavLink>
           {/* <div className="arrow"></div> */}
         </div>
   </div>
     </div>
     </div>
    </>
  )
}

export default AboutUs