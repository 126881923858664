import React from 'react'
import { BrowserRouter as Router ,Navigate,Route, Routes } from 'react-router-dom'
import CancellationsAndRefund from '../Pages/CancellationsAndRefund'
import ContactUs from '../Pages/ContactUs'
import Home from '../Pages/Home'
import PrivacyPolicy from '../Pages/PrivacyPolicy'
import ShippingAndDelivery from '../Pages/ShippingAndDelivery'
import TermsAndConditions from '../Pages/TermsAndConditions'

const PRoute = () => {
  return (
   <Router>
    <Routes>
      <Route path='/' element={<Home/>} />
      <Route path='/contact' element={<ContactUs/>} />
      <Route path='/privacypolicy' element={<PrivacyPolicy/>} />
      <Route path='/termsandconditions' element={<TermsAndConditions/>} />
      <Route path='/cancellationsandrefund' element={<CancellationsAndRefund/>} />
      <Route path='/shippinganddelivery' element={<ShippingAndDelivery/>} />
    </Routes>
   </Router>
  )
}

export default PRoute