import React from 'react'

const Slider = () => {
  return (
    <>
       <div id="carouselExampleCaptions" className="carousel slide Slider-Carousel-In" data-bs-ride="false">
  <div className="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div className="carousel-inner HeaderCarousel">
    <div className="carousel-item active">
      {/* <img src="../Images/cloud-storage-banner-background.jpg" className="d-block w-100" alt="..."/> */}
      <div className="text-white text-center Slider-Text">
                <h3 className="mb-3 textGoldColor RacingFont">We Are Strategic Provider Of IT Solution, IT Consulting & IT Services</h3>
                <p>We guide & manage bussiness transformation for clients through a broads suits of the solution & services</p>
              </div>
              {/* #### square Images ###  */}
               <div className="imgoverlays1"></div>
               <div className="imgoverlays2"></div>
               <div className="imgoverlays3"></div>
               <div className="imgoverlays4"></div>
              
    </div>
    <div className="carousel-item">
      {/* <img src="..." className="d-block w-100" alt="..."/> */}
      <div className="text-white text-center Slider-Text">
      <h3 className='mb-3 textGoldColor RacingFont'>DONE. DELIVERED. APPLAUDED</h3>
        <p>We come highly commended & recommended for the solutions that we have consistently delivered accross different regions</p>
              </div>
              <div className="imgoverlays1"></div>
               <div className="imgoverlays2"></div>
               <div className="imgoverlays3"></div>
               <div className="imgoverlays4"></div>
    </div>
    <div className="carousel-item">
      {/* <img src="..." className="d-block w-100" alt="..."/> */}
      <div className="text-white text-center Slider-Text">
      <h3 className='mb-3 textGoldColor RacingFont'>DONE. DELIVERED. APPLAUDED</h3>
        <p>We come highly commended & recommended for the solutions that we have consistently delivered accross different regions</p>
              </div>
              <div className="imgoverlays1"></div>
               <div className="imgoverlays2"></div>
               <div className="imgoverlays3"></div>
               <div className="imgoverlays4"></div>
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true">
    </span>
    <span className="visually-hidden">Previous</span>
    {/* <img src='../Images/Left ScrollArrow.svg' alt=''></img> */}
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>


    </>
  )
}

export default Slider