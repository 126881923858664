import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import logo from '../Images/Trans Logo@3x.png'
import mobileLogo from '../Images/TranseMobile.png'

const Header = () => {
  const [collapsed, setcollapsed] = useState(true)
  const toggleButton = () => {
    setcollapsed(prev=>!prev)
  }
  return (
    <>
       
        <nav className="navbar p-0">
  <div className="container-fluid position-absolute top-50">
  <NavLink
              as="button"
              className="nav-link m-2 p-0 MobileHeader logo"
              aria-current="page"
              to="/"
            >
              <img
                src={logo}
                alt="Logo..."
                width="280"
                height="80"
                className="d-inline-block d-none d-sm-block logoholder m-3"
              />
              <img
                src={mobileLogo}
                alt="Logo..."
                width="200"
                height="60"
                className="d-block d-sm-none small logoholder m-3 "
              />
            </NavLink>
  
    <button 
    className={`navbar-toggler ${!collapsed && 'collapsed'} d-flex flex-column justify-content-around`}
    onClick={toggleButton} 
    type="button" 
    data-bs-toggle="offcanvas" 
    data-bs-target="#offcanvasTop" 
    aria-controls="offcanvasTop"
    >
    <span className={`toggler-icon ${collapsed && 'collapsed mb-5'} top-bar`} />
    <span className={`toggler-icon  ${collapsed && 'collapsed mb-4 mt-4'} middle-bar`} />
    <span className={`toggler-icon  ${collapsed && 'collapsed mt-5'} bottom-bar`} />
    </button>
    {/* <button type="button" className="" data-bs-dismiss="offcanvas" aria-label="Close">
    </button> */}

<div className="offcanvas offcanvas-top topHeaderbar" tabIndex="-1" id="offcanvasTop" aria-labelledby="offcanvasTopLabel">
  <div className="offcanvas-header">
    {/* <h5 className="offcanvas-title" id="offcanvasTopLabel">Offcanvas top</h5> */}
  </div>
  <div className="offcanvas-body">
 <div className='d-flex justify-content-center text-center menuStyle'>
  <ul>
    <li><NavLink  className='linkStyles' to='/'>Home</NavLink></li>
    <li><NavLink  className='linkStyles' to='/'>Service</NavLink></li>
    <li><NavLink  className='linkStyles' to='/'>About Us</NavLink></li>
    <li><NavLink  className='linkStyles' to='/'>careers</NavLink></li>
    <li><NavLink  className='linkStyles' to='/contact'>Contact Us</NavLink></li>
  </ul>
 </div>
  </div>
</div>
  </div>
</nav>
    </>
  )
}

export default Header